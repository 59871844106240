<template>
    <div class="fantasy-container">
        <!-- empty -->
        <div class="empty" :style="{ 'height': `${emptyHeight}px` }"></div>
        <!-- content text -->
        <Row class="text" type="flex" justify="center" align="middle">
            <Col :span="22" :md="18" :lg="18">
                <p>Classic authors like Hans Christian Anderson and the Brothers Grimm were able to weave tales of magic that sparked the imaginations of all who encountered their stories.</p>
                <br/>
                <p>Fashion is storytelling on a personal scale. We create personas and “read” people, in part, by what they are wearing, so essentially a dress is never just a dress. Some of the ethereal pieces captured in print document passing trends, others speak to a deeper narrative, touching less on individual wants than on shared fantasies or fears.</p>
            </Col>
        </Row>
        <!-- <div class="text">
            <p>Classic authors like Hans Christian Anderson and the Brothers Grimm were able to weave tales of magic that sparked the imaginations of all who encountered their stories.</p>
            <br/>
            <p>Fashion is storytelling on a personal scale. We create personas and “read” people, in part, by what they are wearing, so essentially a dress is never just a dress. Some of the ethereal pieces captured in print document passing trends, others speak to a deeper narrative, touching less on individual wants than on shared fantasies or fears.</p>
        </div> -->
        <!-- content -->
        <Row class="fantasy" type="flex" justify="center" align="middle">
            <Col :span="22" :md="18" :lg="18">
                <ul class="waterfall"> 
                    <li v-for="item in 7" :key="item" class="item">
                        <img :src="require(`@img/fantasy/f-${item}.jpg`)">
                    </li> 
                </ul>
            </Col>
        </Row>
        <div class="footer">
            <img :src="require(`@img/fantasy/footer.jpg`)" alt="">
            <copy-right />
        </div>
    </div>
</template>

<script>
import common from '@M/common';
import CopyRight from '@C/CopyRight';

export default {
    name: 'Fantasy',
    mixins: [common],
    components: {
        CopyRight
    },
    data() {
        return {}
    },
    created() {
        this.setCoverImage(require('@img/fantasy/banner.jpg'));
    },
    mounted() {
        this.addCoverResize();
    },
    beforeDestroy() {
        this.removeCoverResize();
    }
}
</script>

<style lang="scss"> 
.fantasy-container .text {
    word-break: break-all;
    background: #2F2F2D;
    color: #fff;
    min-height: 350px;
    // padding: 5rem 3rem;
    p {
        line-height: 2;
        word-break: break-word;
    }
}
.fantasy {
    background: #fff;
    padding: 1rem 0 6rem 0;
    .waterfall { 
        column-count: 2;
        column-gap: 0px;
        .item {
            img {
                width: 100%;
                height: inherit;
                vertical-align: text-bottom;
            }
        }
    }

}
.footer {
    width: 100%;
    img {
        width: 100%;
        vertical-align: bottom;
    }
}
</style>